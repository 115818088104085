html {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body, html {
  height: 100%;
  margin: 0;
}

.content {
  max-width: 1280px;
  margin: 0 auto;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  border-bottom: 1px solid #f06603;
  padding: 15px;
}

.content-title {
  text-align: center;
  position: relative;

  &::after {
    background-color: #f06603;
    position: absolute;
    content: '';
    height: 2px;
    width: 60px;
    left: 50%;
    transform: translateX(-50%);
    top: 40px
  }
}

header {
  flex: 0 0 auto;
}

main {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: #000;
  text-decoration: none;

  &:visited, &:focus {
    color: #000;
  }
}

// burger menu
.hamburger-icon {
  display: none;
  font-size: 40px;
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 25px;
  z-index: 1000;
  background: none;
  border: none;
  color: #f06603;
  font-weight: 600;

  @media (max-width: 767px) {
    display: initial;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 999;

  .menu-contacts-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    a {
      color: #fff;
    }
  }
}

.menu.open {
  transform: translateX(0);
}

.close-icon {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #fff;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 60px 0 0 0;
}

.menu ul li {
  margin: 20px 0;
}

.menu ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

// header
.header {
  position: relative;

  svg {
    height: 24px;
    width: 24px;
  }

  .tel, .address {
    svg {
      flex-shrink: 0;
      fill: #f06603;
    }
  }
}

.logo {
  max-height: 90px;
}

.info {
  font-weight: 600;
  display: flex;
  align-items: center;

  & + .info {
    margin-top: 10px;
  }

  svg {
    margin-right: 10px;
  }
}

.contacts-info {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }
}

.header-contacts {
  & + .header-contacts {
    margin-left: 15px;
  }
}

.header-content {
  color: #333333;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: #333333;
  }
}

.navigation {
  padding: 25px;
  background-color: #333333;

  @media (max-width: 767px) {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;

    a {
      color: #ffffff;
    }
  }

  li {
    + li {
      padding-left: 10px;
    }
  }
}

// intro-wrapper
.intro-wrapper {
  position: relative;
  width: 100%;
  //max-height: 450px;
  overflow: hidden;

  //@media (max-width: 1023px) {
  //  height: 1020px;
  //}
}

//.swiper-container {
//  width: 100%;
//  height: 100%;
//}
//
//.swiper-slide {
//  display: flex;
//  align-items: center;
//
//  @media (max-width: 1023px) {
//    align-items: flex-start;
//  }
//}
//
//.swiper-slide-image {
//  width: 100%;
//  height: 100%;
//  object-fit: cover;
//}
//
//.slider-image {
//  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
//  width: 100%;
//  height: 100%;
//  background-size: cover;
//  background-repeat: no-repeat;
//  position: absolute;
//}
//
//.slider-description-wrapper {
//  position: relative;
//  z-index: 1;
//  padding: 50px;
//  color: #fff;
//  box-sizing: border-box;
//  max-height: 58%;
//  max-width: 60%;
//
//  @media (max-width: 1180px) {
//    max-width: 50%;
//  }
//
//  @media (max-width: 1023px) {
//    max-width: 100%;
//    padding: 15px;
//  }
//
//  .slider-title {
//    text-transform: uppercase;
//    color: #f06603;
//    font-size: 26px;
//
//    @media (max-width: 767px) {
//      font-size: 20px;
//      margin: 10px 0;
//    }
//  }
//
//  .slider-sub-title {
//    @media (max-width: 767px) {
//      margin: 10px 0;
//      font-size: 16px;
//    }
//  }
//
//  .slider-text {
//    span {
//      color: #f06603;
//      font-weight: 600;
//    }
//  }
//
  .slider-button {
    color: #ffffff;
    background-color: #f06603;
    border-radius: 5px;
    border: 1px solid #f06603;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    transition: background-color .3s ease, color .3s ease;

    &:hover {
      cursor: pointer;
      background-color: #c24f02;
      color: #ffffff;
    }
  }
//}

.background-section {
  position: relative;
  width: 100%;
  height: 450px; /* Фиксированная высота секции */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

.background-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('assets/slider-4.jpg') no-repeat center center/cover;
  z-index: 1;
  display: none;
}

.background-section video {
  position: absolute;
  top: 50%; /* Центрирование по вертикали */
  left: 50%;
  width: 100%;
  height: auto; /* Автоматическая высота для сохранения пропорций */
  transform: translate(-50%, -50%); /* Смещение для точного центрирования */
  object-fit: cover;
  object-position: center; /* Центрирование видео внутри контейнера */
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  h3 {
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }
}

@media (max-width: 768px) {
  .background-section::before {
    display: block;
  }

  .background-section video {
    display: none;
  }
}

.promo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px auto;

  @media (max-width: 767px) {
    justify-content: center;
  }

  img {
    max-height: 60px;
    flex-basis: 20%;
  }

  p {
    flex-basis: 70%;

    @media (max-width: 767px) {
      flex-basis: 100%;
      text-align: center;
    }
  }
}

//.form-wrapper {
//  position: absolute;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  margin: auto;
//  width: 100%;
//  display: flex;
//  align-items: center;
//
//  @media (max-width: 1023px) {
//    top: 130px;
//  }
//
//  @media (max-width: 767px) {
//    top: 225px;
//  }
//}
//
//.form-container {
//  max-width: 450px;
//  width: 100%;
//  margin-left: auto;
//  margin-right: 80px;
//  position: relative;
//  z-index: 4;
//
//  @media (max-width: 1023px) {
//    margin: 0 auto;
//  }
//}

.form-content {
  color: #333333;
  //max-width: 450px;
  width: 100%;
  //margin-left: auto;
  //margin-right: 0;
  //border-radius: 20px;
  //border: 1px solid rgba(47, 47, 47, 0.9);
  //background: rgba(47, 47, 47, 0.9);
  //-webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  //padding: 50px 30px;
  box-sizing: border-box;
  //
  //@media (max-width: 1023px) {
  //  margin: 0 auto;
  //}
  //
  //@media (max-width: 767px) {
  //  padding: 20px 15px;
  //}

  a {
    color: #2471b8;
  }

  .label {
    margin-bottom: 11px;
    color: #333333;
  }

  .form-control {
    margin-bottom: 20px;
  }

  .form-control-wrap {
    display: flex;
    width: 100%;

    .form-input {
      width: 100%;
      height: 58px;
      border-radius: 5px;
      border: 1px solid rgba(47, 47, 47, 0.9);
      background: rgb(63 63 63 / 9%);
      //-webkit-backdrop-filter: blur(7px);
      //backdrop-filter: blur(7px);
      padding-left: 10px;
      padding-right: 10px;
      color: #333333;
      font-size: 20px;

      @media (max-width: 767px) {
        height: 40px;
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }
}

.form-title {
  text-align: center;
  font-size: 26px;
  line-height: 32px;
  margin: 0 0 15px;
  color: #f06603;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.form-sub-title {
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}

.form-textarea {
  resize: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-content {
  .form-control-wrap {
    .form-input {
      &.form-textarea {
        height: auto;
      }
    }
  }
}

.submit-button {
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: #f06603;
  padding: 15px 22px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  -webkit-transition: 0.25s all;
  transition: 0.25s all;

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: #d55903;
  }
}

.form-error {
  font-size: 16px;
  color: #ff4646;
}

.success-message-wrapper {
  color: #f06603;
  margin: 5px 0;
}

// services
.services-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0;

  .service-item {
    box-sizing: border-box;
    flex-basis: 50%;
    padding: 10px;
    text-align: center;

    @media (max-width: 1023px) {
      flex-basis: 100%;
    }

    .service-item-text {
      text-align: left;
    }
  }

  .why-we-item {
    flex-basis: 25%;

    @media (max-width: 1023px) {
      flex-basis: 50%;
    }


    @media (max-width: 767px) {
      flex-basis: 100%;
    }
  }

  svg {
    width: 64px;
    height: 64px;
    fill: #f06603;
  }
}

.image-gallery {
  margin: -8px;

  & > div {
    margin: 8px;
  }
}

.gallery-wrapper {
  .slider-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .image-slider {
    width: 80%;
    height: 80%;
  }
}

.partner-wrapper {
  margin: 40px auto;

  .swiper-slide {
    flex-direction: column;
    transform: scale(.6);
    opacity: .6;
    transition: transform .3s ease, opacity .3s ease;

    @media (max-width: 767px) {
      transform: scale(1);
      opacity: 1;
    }
  }

  .swiper-slide-next {
    transform: scale(1.1);
    opacity: 1;

    @media (max-width: 767px) {
      transform: scale(1);
      opacity: 1;
    }
  }

  .partner-slide {
    color: #f06603;
    text-align: center;
    padding: 15px 0;
    box-sizing: border-box;

    @media (max-width: 767px) {
      margin: 0 auto;
    }

    img {
      width: 250px;
      height: 60px;
    }
  }
}

// contacts
.contacts-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 15px;
  flex-wrap: wrap;

  iframe {
    @media (max-width: 1140px) {
      margin: 0 auto;
    }
  }
}

.contacts-info-wrapper {
  padding: 0 15px;

/*  @media (max-width: 1140px) {
    margin: 15px auto;
  }*/

  svg {
    height: 24px;
    width: 24px;
  }

  .tel, .address {
    svg {
      flex-shrink: 0;
      fill: #f06603;
    }
  }
}

// footer
.footer {
  background-color: #201c1c;
  color: #dbe2e3;
  padding: 20px;
  text-align: center;

  a {
    color: #dbe2e3;

    &:visited, &:focus {
      color: #dbe2e3;
    }
  }

  svg {
    height: 24px;
    width: 24px;
  }

  .tel, .address {
    svg {
      flex-shrink: 0;
      fill: #f06603;
    }
  }
}

.about-wrapper {
  background-image: url("assets/about.jpg"), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
  background-blend-mode: overlay;
  background-size: cover;
  color: #ffffff;
  padding: 50px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 15px;
  }

  .content-title {
    margin: 0 0 20px;

    &::after {
      content: none;
    }
  }

  p {
    + p {
      margin-top: 50px;
    }
  }

  .content {
    max-width: 620px;
    font-size: 20px;
  }
}

.employment {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    display: block;
  }
}

.employee-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    margin: 0 auto;
  }

  + .employee-card {
    margin-left: 15px;

    @media (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    }
  }
}

.photo-container {
  margin-bottom: 15px;
}

.photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.details {
  font-family: Arial, sans-serif;
}

.position {
  font-weight: bold;
  margin-bottom: 5px;
}

.name {
  font-weight: normal;
  color: #c3c2c2;
}

.footer-sections {
  display: flex;
  align-items: flex-start;
  text-align: left;
  flex-wrap: wrap;

  .footer-section {
    box-sizing: border-box;
    flex-basis: 32%;
    padding: 10px 30px;

    + .footer-section {
      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (max-width: 1023px) {
      flex-basis: 49%;
    }

    @media (max-width: 767px) {
      flex-basis: 100%;
    }

    p {
      margin: 0;

      + p {
        margin-top: 10px;
      }
    }
  }
}

.requisites {
  margin-top: 40px;

  span {
    display: block;

    + span {
      margin-top: 20px;
    }
  }
}

.loading-image {
  width: 100%;
}